<template>
    <v-card color="#F6F9FC" flat class="rounded-0 pt-6">
        <v-container>
            <v-row>
                <v-col cols="12" lg="3">
                    <DashboardSidebar></DashboardSidebar>
                </v-col>
                <v-col cols="12" lg="9">
                    <v-card flat class="pa-4">
                        <v-row align="center">
                            <v-col cols="12" lg="6" md="2">
                                <p class="mb-0 text-h6 font-weight-medium">{{ $t('购买记录') }}</p>
                            </v-col>
                            <v-col cols="12" lg="6" md="10">
                                <div class="d-flex align-center justify-end">
                                    <span>{{ $t('类型') }}：</span>
                                    <v-card flat height="40">
                                        <v-select :items="statusList" v-model="postParam.pay_status" outlined
                                            dense
                                            @change="getlist"
                                            ></v-select>
                                    </v-card>
                                </div>
                            </v-col>
                            <!-- <v-col cols="12" lg="10" md="10">
                                <v-row>
                                    <v-col cols="12" lg="7" md="7">
                                        <div class="d-flex align-center justify-end">
                                            <span>{{ $t('时间') }}：</span>
                                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                                :return-value.sync="date" transition="scale-transition" offset-y
                                                min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-card height="40" flat width="150">
                                                        <v-text-field v-model="date"
                                                            prepend-inner-icon="mdi-calendar-month-outline" readonly
                                                            v-bind="attrs" v-on="on" outlined dense></v-text-field>
                                                    </v-card>
                                                </template>
                                                <v-date-picker v-model="date" no-title scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="menu = false">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                                                        OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                            <v-icon>mdi-minus</v-icon>
                                            <v-menu ref="menu" v-model="menu1" :close-on-content-click="false"
                                                :return-value.sync="date" transition="scale-transition" offset-y
                                                min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-card height="40" flat width="150">
                                                        <v-text-field v-model="date"
                                                            prepend-inner-icon="mdi-calendar-month-outline" readonly
                                                            v-bind="attrs" v-on="on" outlined dense></v-text-field>
                                                    </v-card>
                                                </template>
                                                <v-date-picker v-model="date" no-title scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="menu1 = false">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn text color="primary" @click="$refs.menu1.save(date)">
                                                        OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5">
                                        <div class="d-flex align-center">

                                            <v-card height="40" flat class="mr-2">
                                                <v-text-field outlined dense :placeholder="$t('模版名称')"></v-text-field>
                                            </v-card>
                                            <v-btn color="primary" height="40"
                                                depressed><v-icon>mdi-magnify</v-icon></v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                                <div class="d-flex justify-space-between align-center">



                                </div>
                            </v-col> -->

                        </v-row>

                        <v-divider class="my-4"></v-divider>
                        <div>
                            <v-card flat v-for="(bitem,bidx) in buylist" :key="bidx">
                                <v-card color="#F2F2F2"
                                    class="d-flex align-center justify-space-between rounded-0 px-6 py-3" flat>
                                    <span class="text-body-1 d-none d-md-block">{{$t('订单编号')}} {{ bitem.order_sn }}</span>
                                    <span class="grey--text">{{$t('时间')}} {{ bitem.updatetime }}</span>
                                </v-card>
                                <v-row class="ma-0">
                                    <v-col cols="9">
                                        <div class="d-flex overflow-hidden">
                                            <img :src="bitem.cover_img" alt="" width="120" />
                                            <div class="ml-4">
                                                <p class="text-subtitle-1 ">{{ bitem.name }}</p>
                                                <p class="text--secondary text-body-2 text-no-wrap text-truncate">
                                                    {{ bitem.des }}
                                                </p>
                                            </div>

                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <h6 class="danger--text text-h6 font-weight-bold text-right" v-if="bitem.type==2">{{ bitem.pay_symbol }}{{ bitem.pay_money }}</h6>
                                        <h6 class="success--text text-h6 font-weight-bold text-right" v-if="bitem.type==1">{{ $t('免费') }}</h6>
                                    </v-col>
                                </v-row>




                            </v-card>
                        </div>
                    </v-card>



                </v-col>
            </v-row>
        </v-container>

    </v-card>
</template>
<script>
import DashboardSidebar from '../../components/DashboardSidebar.vue'
export default {
    components: {
        DashboardSidebar
    },
    data() {
        return {
            menu: false,
            menu1: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            buylist:[],
            statusList: [{
                text: this.$t('全部'),
                value: '-1',

            },
            {
                text: this.$t('未支付'),
                value: '0',

            },
            {
                text: this.$t('已支付'),
                value: '1',

            }
            ],
            postParam:{
                pay_status:'1',
                current_page:1,
                page_number:50
            }
        }
    },
    mounted(){
        this.getlist();
    },
    methods:{
        getlist(){
            this.$api.template.order_list(this.postParam).then(res=>{
                if(res.status){
                    this.buylist=res.data.records;
                }
            })
        }
    }
}
</script>
